import './navbar.css';
import React, {  useEffect } from 'react';
const Navbar = () =>  {






    return (
      <>

      <nav id="menu">
      <a href="#">Inicio</a>
    <a href="#informacion">Información</a>
	  <a href="#ropa">Ropa</a>
      
      <a href="#contacto">Contacto</a>
      
      <span className="indicador" id="indicador"></span>
    </nav>
    </>
    );
  }
  
  export default Navbar;