import './instagram.css';
import imagen from './img/01.jpg';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import bisuteria from "../../assets/jolleria.jpeg"
import bolsos from "../../assets/bolsos.jpeg"
import ropa from "../../assets/ropa.jpeg"
import cinturones from "../../assets/cinturones.jpeg"
import complementos from "../../assets/fular.jpeg"

// import required modules
import { Pagination, Navigation } from "swiper";
const Instagram = () =>  {
    return (
      <div className="seccion" id="ropa">
      
			 <div class="container">
       <h3>Qué Vendemos</h3>
       <Swiper
        slidesPerView={4}
        spaceBetween={10}
        pagination={{
          clickable: true,
          color: '#ac7680'
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >

        <SwiperSlide class="swiper-slide"> 
        <img src={ropa} alt=""></img>
                    <div class="card-description">
                        <div class="card-title">
                            <h4>Ropa</h4>
                        </div>
                        <div class="card-text">
                            <p>Abrimos las puertas a la creatividad y a la imaginación vistiendo a mujeres que van a la tendencia o tanto casual como sport. Disponemos de amplio rango de tallajes.
                                Traemos novedades cada semana.  </p>
                        </div>
                        <div class="card-link">
                          
                        </div>
                    </div></SwiperSlide>
        <SwiperSlide class="swiper-slide"> <img src={bolsos} alt=""></img>
                    <div class="card-description">
                        <div class="card-title">
                            <h4>Bolsos</h4>
                        </div>
                        <div class="card-text">
                            <p>Bandoleras, bolsos de hombro, bolsos de mano y carteras.
                            Además disponemos de diferentes bandoleras para hacer tu bolso único.
                              </p>
                        </div>
                        <div class="card-link">
                          
                        </div>
                    </div></SwiperSlide>
        <SwiperSlide class="swiper-slide"> <img src={bisuteria} alt=""></img>
                    <div class="card-description">
                        <div class="card-title">
                            <h4>Bisutería</h4>
                        </div>
                        <div class="card-text">
                            <p>Bisutería de acero inoxidable, resistentes al agua y jabón, todos los anillos, collares y pulseras son ajustables. Recibimos máximo dos unidades de cada modelo para hacer tu complemento más exclusivo y especial. </p>
                        </div>
                        <div class="card-link">
                          
                        </div>
                    </div></SwiperSlide>
        <SwiperSlide class="swiper-slide"> <img src={cinturones} alt=""></img>
                    <div class="card-description">
                        <div class="card-title">
                            <h4>Cinturones</h4>
                        </div>
                        <div class="card-text">
                            <p>La combinación perfecta para lucir un look único y personal.</p>
                        </div>
                        <div class="card-link">
                          
                        </div>
                    </div></SwiperSlide>
        <SwiperSlide class="swiper-slide"> <img src={complementos} alt=""></img>
                    <div class="card-description">
                        <div class="card-title">
                            <h4>Complementos</h4>
                        </div>
                        <div class="card-text">
                            <p>Bufandas, pañuelos, fulares para todas las estaciones del año.</p>
                        </div>
                        <div class="card-link">
                          
                        </div>
                    </div></SwiperSlide>
       
      </Swiper>
      </div>
		</div>
    );
  }
  
  export default Instagram;