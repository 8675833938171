
//import './App.scss'
import './estilos.css'
import Navbara from './components/navbar/navbar.js';
import Front from './components/front-slide/front-slide.js';
import Info from './components/info/info.js';
import Instagram from './components/instagram/instagram.js';
import Footer from './components/footer/footer.js';




const App = () =>  {


  return (
	<div>
   		<Navbara></Navbara>
		<Front></Front>
    	
	

		<div className="secciones">

			<Info></Info>
			<Instagram></Instagram>
			
      		
      		<Footer></Footer>
		
		</div>

	</div> 
	
  );
}

export default App;
