import './front-slide.css';
import HeroSlider, { Overlay, Slide, Nav } from "hero-slider";


import calle from  "../../assets/calle_negro.png";
import cartel from "../../assets/logo_negro.png";
import dentro from "../../assets/dentro_negro.png";



const Front = () =>  {

 
    return (
      <>
      
      <HeroSlider
      height={"100vh"}
      autoplay
      controller={{
        slidingAnimation: 'wipe' ,
        initialSlide: 1,
        sliderFadeInDuration: 1500,
        slidingDuration: 300,
        slidingDelay: 1,
        autoplayDuration: 100,
        shouldManageAnimationSequence: true,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >
      <Overlay>
      
        <div className="Container">
          <h1 >Mulán Boutique</h1>
          <h2 >
            Define a la mujer creativa, guerrera y con personalidad
          </h2>
        </div>
      </Overlay>

      <Slide
        
        label="Giau Pass - Italy"
        background={{
        
         
          backgroundImageSrc: dentro,
          
          
         
        }}
      />

      <Slide
        
        label="Bogliasco - Italy"
        background={{
          backgroundImageSrc: calle,
        
        }}
      />

      <Slide
        
        label="County Clare - Ireland"
        background={{
          backgroundImageSrc: cartel,
          
        }}
      />



    <Nav />
    </HeroSlider>
    </>
   
    );
  }
  
  export default Front;