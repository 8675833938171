import './info.css';
import logo from '../../assets/2_11zon.jpg'
const Info = () =>  {
    return (

      <div  id="informacion">
      
      <section class="about" id="about">
	
      <div class="image">
        <img src={logo} alt=""></img>
      </div>
    
      <div class="content">
        <h3>Sobre Mulán Boutique</h3>
        <p>Mulán Boutique define a la mujer creativa, guerrera y con personalidad, viste con identidad a las Portugalujas, Santurtziarras y a las demás grandes mujeres.</p>
        <p>Las prendas son seleccionadas una por una, por lo que podremos ver diferentes estilos de mujeres y contamos con un amplio abanico de tallaje. Mulán te anima a que luzcas tu silueta y tu esencia.</p>
        
        
        
      </div>
    
    </section>
    </div>
    );
  }
  
  export default Info;