import './footer.css';
import 'boxicons';
import logo from '../../assets/2_11zon.jpg'
import instagram from '../../assets/instagram-2016-5.svg'

const Footer = () =>  {
    return (
		<div className="seccion" id="contacto">
		<footer class="footer-distributed">

			<div class="footer-left">

				<h3>Mulán</h3><h3><span>Boutique</span></h3>

				<p class="footer-links">
					<a href="#" class="link-1">Inicio</a>
					
					<a href="#ropa">Ropa</a>
				
					<a href="#informacion">Información</a>
				
					<a href="#contacto">Contacto</a>
					
				</p>
				<p class="footer-company-name-title"> Horario:</p>
				<p class="footer-company-name"> Lunes-Sabado: 10:00h-14:00h y 17:00h-21:00h </p>
				
				<p class="footer-company-name"> Domigos: 11:00h-14:00h </p>
				
			</div>

			<div class="footer-center">

				<div>
				<box-icon class="icon" name='map-pin' animation='flashing' ></box-icon>
				<p><a className="black" href="https://www.google.com/maps/place/Mul%C3%A1n+Boutique/@43.321319,-3.0242215,17z/data=!3m1!4b1!4m5!3m4!1s0xd4e59a1314d5821:0xcb60ceb2993deb61!8m2!3d43.3213038!4d-3.0220445">Calle General Castaños, </a></p>
				<p><a className="black" href="https://www.google.com/maps/place/Mul%C3%A1n+Boutique/@43.321319,-3.0242215,17z/data=!3m1!4b1!4m5!3m4!1s0xd4e59a1314d5821:0xcb60ceb2993deb61!8m2!3d43.3213038!4d-3.0220445">51. Portugalete 48920</a></p>
				</div>

				<div>
				<box-icon class="icon" name='phone' animation='tada' ></box-icon>
					
				<p>635699256</p>
				</div>

				<div>
				<box-icon class="icon" name='map-pin' animation='flashing' ></box-icon>
				<p><a className="black" href="https://www.google.com/maps/place/Mul%C3%A1n+Boutique/@43.3298283,-3.0358894,17z/data=!3m1!4b1!4m5!3m4!1s0xd4e5974dfbc2081:0xf96be182aeb23145!8m2!3d43.3298283!4d-3.0336954">Calle Vapor Habana, </a></p>
				<p><a className="black" href="https://www.google.com/maps/place/Mul%C3%A1n+Boutique/@43.3298283,-3.0358894,17z/data=!3m1!4b1!4m5!3m4!1s0xd4e5974dfbc2081:0xf96be182aeb23145!8m2!3d43.3298283!4d-3.0336954">11. Santurtzi 48980</a></p>
				</div>

				<div>
				<box-icon class="icon" name='phone' animation='tada' ></box-icon>
					
				<p>632028626</p>
				</div>



				<div>
				<box-icon class="icon" name='mail-send'  ></box-icon>
					<p><a href="mailto:mulanboutiquee@gmail.com ">mulanboutiquee@gmail.com </a></p>
				</div>

			</div>

			<div class="footer-right">

				<p class="footer-company-about">
					<span>Sobre nosotros</span>
					Mulán Boutique define a la mujer creativa, guerrera y con personalidad, viste con identidad a las Portugalujas, Santurtziarras y a las demás grandes mujeres.
				</p>

				<div class="footer-icons">

				<a href="https://www.instagram.com/mulanboutiquee/?hl=es"><box-icon type='logo' name='instagram' size='lg'></box-icon></a>

				</div>

			</div>

		</footer>
		</div>
    );
  }
  
  export default Footer;